<template>
  <v-container class="mkt-content-container mt-10">
    <v-row>
      <v-col cols="12" sm="8">
        <v-row class="body-2 mb-3">
          <v-col cols="12">
            <router-link to="/home">
              Home
            </router-link>
            >
            <span>
          Cadastro
        </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
          <span class="display-1 text--primary--color font-weight-bold">
            Cadastre-se e receba todas as nossas novidades
          </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="5">
            Cadastre-se e fique por dentro de todas as novidades e ofertas do marketplace Proxpect.
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-rd-form-subscription-embedded/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4" v-if="!isMobile">
        <v-img src="../../../assets/capa-form-rd.png"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import RdFormSubscriptionEmbedded from './RdFormSubscriptionEmbedded.vue';

export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    pxRdFormSubscriptionEmbedded: RdFormSubscriptionEmbedded,
  },
};
</script>
